<template>
  <main class="mr-4">
    <trac-back-button>Back</trac-back-button>
    <trac-loading v-if="isLoading" />
    <h3 class="my-8 font-bold text-sm">KYC (Settlement Preference)</h3>

    <div class="shadow-lg rounded-md p-4 pt-8 md:w-9/12 mx-auto my-8 md:px-12">
      <h3 class="my-5 font-bold text-primaryBlue">Settlement Preference</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 mx-4 md:mx-10 my-12 gap-4">
        <div
          @click="$router.push({ name: 'AddBankDetails' })"
          class="
            shadow-md
            rounded-md
            p-5
            cursor-pointer
            hover:bg-accentLight
            flex
            items-center
            justify-between
            gap-3
          "
        >
          <div class="flex items-center md:gap-3">
            <img
              src="./../../../assets/svg/pin-management/settlement-pref.svg"
              alt=""
            />
            <p class="text-xs">Settlement Account</p>
          </div>
          <img
            src="./../../../assets/svg/pin-management/chevron-icon-right.svg"
            alt=""
          />
        </div>
        <div
          @click="$router.push({ name: 'PinManagment' })"
          class="
            shadow-md
            rounded-md
            p-5
            cursor-pointer
            hover:bg-accentLight
            flex
            items-center
            justify-between
            gap-3
          "
        >
          <div class="flex items-center gap-3">
            <img
              src="./../../../assets/svg/pin-management/settlement-pref.svg"
              alt=""
            />
            <p class="text-xs">Manage Wallet Pin</p>
          </div>
          <img
            src="./../../../assets/svg/pin-management/chevron-icon-right.svg"
            alt=""
          />
        </div>
        <div
          @click="$router.push({ name: 'AddSettlementPreference' })"
          class="
            shadow-md
            rounded-md
            p-5
            cursor-pointer
            hover:bg-accentLight
            flex
            items-center
            justify-between
            gap-3
          "
        >
          <div class="flex items-center gap-3">
            <img
              src="./../../../assets/svg/pin-management/settlement-pref.svg"
              alt=""
            />
            <p class="text-xs">Settlement Preference</p>
          </div>
          <img
            src="./../../../assets/svg/pin-management/chevron-icon-right.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  async mounted() {
    
  },
  methods: {
    
  },
};
</script>

<style>
</style>